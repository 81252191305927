import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import * as validators from '../../../../util/validators';
import { post } from '../../../../util/api';

import { Button, FieldTextInput, Form, H2 } from '../../../../components';
import { ContactFormTemplate } from './ContactFormTemplate';

import css from './SectionContact.module.css';
import ContactBackground from './ContactBackground.png';

const submitContactMessage = async (values, setSubmitting, setSubmitError, setSubmitSuccess) => {
    setSubmitting(true);
    setSubmitError(null);
    setSubmitSuccess(null);

    if (values) {
        const { firstName, lastName, email, phoneNumber } = values;
        const emailBody = ContactFormTemplate(firstName, lastName, email, phoneNumber);
        const contactForm = {
            subject: `New contact request from ${email}`,
            text: emailBody,
        };

        try {
            const response = await post('/api/sendgrid-schedule-email', contactForm)

            if (response === 'OK') {
                setSubmitSuccess('Email sent successfully');
                console.log('Email sent successfully');
            }
        } catch (error) {
            setSubmitError('An error occurred while sending the email. Please try again later.');
            console.log('An error occurred while sending the email:', error);
        } finally {
            setSubmitting(false);
        }
    }
};

const SectionContact = props => {
    const intl = useIntl();
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(null);
    return (
        <div className={css.root}>
            <H2 className={css.heading}>
                <FormattedMessage
                    id="LandingPage.SectionContact.title"
                />
            </H2>
            <div className={css.container}>
                <img className={css.image} src={ContactBackground} />
                <FinalForm
                    onSubmit={values => { submitContactMessage(values, setSubmitting, setSubmitError, setSubmitSuccess) }}
                    render={formRenderProps => {
                        const { handleSubmit, invalid, pristine } = formRenderProps;
                        const required = validators.required('This field is required');
                        const emailValid = validators.emailFormatValid('Invalid email address');
                        const submitDisabled = invalid || pristine || submitting;
                        return (
                            <Form onSubmit={handleSubmit} className={css.contactForm}>
                                <div className={css.inputsContainer}>
                                    <div className={css.name}>

                                        <FieldTextInput
                                            id="firstName"
                                            name="firstName"
                                            className={css.firstName}
                                            type="text"
                                            validate={required}
                                            label={intl.formatMessage({
                                                id: 'LandingPage.SectionContact.firstNameLabel',
                                            })}
                                        />
                                        <FieldTextInput
                                            id="lastName"
                                            name="lastName"
                                            className={css.lastName}
                                            type="text"
                                            validate={required}
                                            label={intl.formatMessage({
                                                id: 'LandingPage.SectionContact.lastNameLabel',
                                            })}
                                        />
                                    </div>
                                    <FieldTextInput
                                        id="email"
                                        name="email"
                                        className={css.email}
                                        type="email"
                                        validate={validators.composeValidators(required, emailValid)}
                                        label={intl.formatMessage({
                                            id: 'LandingPage.SectionContact.emailLabel',
                                        })}
                                    />
                                    <FieldTextInput
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        className={css.phoneNumber}
                                        type="tel"
                                        validate={required}
                                        label={intl.formatMessage({
                                            id: 'LandingPage.SectionContact.phoneNumberLabel',
                                        })}
                                    />
                                </div>
                                {submitError && <p className={css.error}>{submitError}</p>}
                                {submitSuccess && <p className={css.success}>{submitSuccess}</p>}
                                <Button className={css.submit} type="submit" disabled={submitDisabled}>
                                    {submitting ? (
                                        <FormattedMessage id="LandingPage.SectionContact.submitting" />
                                    ) : (
                                        <FormattedMessage id="LandingPage.SectionContact.submit" />
                                    )}
                                </Button>
                                <p className={css.info}>
                                    <FormattedMessage id='LandingPage.SectionContact.info' />
                                </p>
                            </Form>
                        )
                    }}
                />
            </div>
        </div>
    );
};

export default SectionContact;