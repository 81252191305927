import React from "react";
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';

import { H2, NamedLink } from '../../../../components';
import Arrow from '../SectionHero/img/triangle-24.png';
import Number1 from './img/number1.svg';
import Number2 from './img/number2.svg';
import Number3 from './img/number3.svg';


import css from './SectionHow.module.css';

const SectionHow = props => {
    return (
        <div className={css.root}>
            <H2 className={css.heading}>
                <FormattedMessage
                    id="LandingPage.SectionHow.title"
                    values={{ lineBreak: <br /> }}
                />
            </H2>
            <div className={css.container}>
                <div className={css.line}>
                    <img className={css.numberImage} src={Number1} />
                    <div className={css.textContaier}>
                        <p className={css.subTitle}>
                            <FormattedMessage id="LandingPage.SectionHow.subTitle1" />
                        </p>
                        <FormattedMessage id="LandingPage.SectionHow.info1" />
                    </div>
                </div>
                <div className={css.line}>
                    <img className={css.numberImage} src={Number2} />
                    <div className={css.textContaier}>
                        <p className={css.subTitle}>
                            <FormattedMessage id="LandingPage.SectionHow.subTitle2" />
                        </p>
                        <FormattedMessage
                            id="LandingPage.SectionHow.info2"
                            values={{ lineBreak: <br /> }}
                        />
                    </div>
                </div>
                <div className={css.line}>
                    <img className={css.numberImage} src={Number3} />
                    <div className={css.textContaier}>
                        <p className={css.subTitle}>
                            <FormattedMessage id="LandingPage.SectionHow.subTitle3" />
                        </p>
                        <FormattedMessage
                            id="LandingPage.SectionHow.info3"
                            values={{ lineBreak: <br /> }}
                        />
                    </div>
                </div>
            </div>
            <NamedLink name="SignupPage" className={css.signupLink}>
                <span className={css.signup}>
                    <FormattedMessage id="LandingPage.SectionHero.button" />
                </span>
                <img src={Arrow} />
            </NamedLink>
        </div>
    )
}

export default SectionHow;