import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';

import { H2, NamedLink } from '../../../../components';

import HexagonBackground from './images/BackgroundHexagon.png';
import agenda from './images/agenda.png';
import browser from './images/browser.png';
import house from './images/house.png';
import skyscraper from './images/skyscraper.png';
import ArrowBlack from './images/triangleBlack.png';
import Arrow from '../SectionHero/img/triangle-24.png';
import css from './SectionWhoUses.module.css';
import { pushToPath } from '../../../../util/urlHelpers';

const SectionWhoUses = props => {
    return (
        <div className={css.root}>
            <img className={css.image} src={HexagonBackground} />
            <H2 className={css.heading}>
                <FormattedMessage
                    id="LandingPage.SectionWhoUses.title"
                />
            </H2>
            <div className={css.container}>
                <div className={css.card}>
                    <div className={css.cardTitle}>
                        <FormattedMessage id="LandingPage.SectionWhoUses.propertyOwners" />
                    </div>
                    <img className={css.icon} src={house} />
                    <a href="/p/property-owners"
                        className={css.button}
                    // onClick={() => {
                    //     pushToPath("https://tackl-zy16gr.mysharetribe-test.com/p/property-owners");
                    // }}
                    >
                        <FormattedMessage id="LandingPage.SectionWhoUses.view" />
                        <img className={css.arrow} src={ArrowBlack} />
                    </a>
                </div>
                <div className={css.card}>
                    <div className={css.cardTitle}>
                        <FormattedMessage id="LandingPage.SectionWhoUses.propertyManagers" />
                    </div>
                    <img className={css.icon} src={agenda} />
                    <a href="/p/property-managers"
                        className={css.button}
                    // onClick={() => {
                    //     pushToPath("https://tackl-zy16gr.mysharetribe-test.com/p/property-managers");
                    // }}
                    >
                        <FormattedMessage id="LandingPage.SectionWhoUses.view" />
                        <img className={css.arrow} src={ArrowBlack} />
                    </a>
                </div>
                <div className={css.card}>
                    <div className={css.cardTitle}>
                        <FormattedMessage id="LandingPage.SectionWhoUses.realtorsBrokers" />
                    </div>
                    <img className={css.icon} src={browser} />
                    <a href="/p/realtors-brokers"
                        className={css.button}
                    // onClick={() => {
                    //     pushToPath("https://tackl-zy16gr.mysharetribe-test.com/p/realtors-brokers");
                    // }}
                    >
                        <FormattedMessage id="LandingPage.SectionWhoUses.view" />
                        <img className={css.arrow} src={ArrowBlack} />
                    </a>
                </div>
                <div className={css.card}>
                    <div className={css.cardTitle}>
                        <FormattedMessage id="LandingPage.SectionWhoUses.commercial" />
                    </div>
                    <img className={css.icon} src={skyscraper} />
                    <a href="/p/commercial"
                        className={css.button}
                    // onClick={() => {
                    //     pushToPath("https://tackl-zy16gr.mysharetribe-test.com/p/commercial");
                    // }}
                    >
                        <FormattedMessage id="LandingPage.SectionWhoUses.view" />
                        <img className={css.arrow} src={ArrowBlack} />
                    </a>
                </div>
            </div>
            <div className={css.learnHow}>
                <FormattedMessage
                    id="LandingPage.SectionWhoUses.learnHow"
                    values={{ lineBreak: <br /> }}
                />
                <a href='/p/about' className={css.signupLink}>
                    <span className={css.signup}>
                        <FormattedMessage id="LandingPage.SectionWhoUses.button" />
                    </span>
                    <img src={Arrow} />
                </a>
            </div>
        </div>
    );
};

export default SectionWhoUses;