import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';

import { H2, NamedLink } from '../../../../components';

import Arrow from '../SectionHero/img/triangle-24.png';
import SectionBackground from './SectionBackground.png';
import hexagon from './hexagon.png';

import css from './SectionDifference.module.css';

const SectionDifference = props => {
    return (
        <div className={css.root}>
            <img className={css.image} src={SectionBackground} />
            <H2 className={css.heading}>
                <FormattedMessage id="LandingPage.SectionDifference.title" />
            </H2>
            <div className={css.container}>
                <div className={css.card}>
                    <p className={css.cardTitle}>
                        <FormattedMessage id="LandingPage.SectionDifference.withoutTackl" />
                    </p>
                    <div className={css.item}>
                        <img className={css.bullet} src={hexagon} />
                        <span>Scheduling nightmare for multiple bids from different contractors</span>
                    </div>
                    <div className={css.item}>
                        <img className={css.bullet} src={hexagon} />
                        <span>No standard pricing - crazy differences in estimates</span>
                    </div>
                    <div className={css.item}>
                        <img className={css.bullet} src={hexagon} />
                        <span>Waiting days or weeks for a call back or appointment and burning relationships with contractors</span>
                    </div>
                </div>
                <div className={css.card}>
                    <p className={css.cardTitle}>
                        <FormattedMessage id="LandingPage.SectionDifference.withTackl" />
                    </p>
                    <div className={css.item}>
                        <img className={css.bullet} src={hexagon} />
                        <span>Free, instant personalized quote without talking to anyone</span>
                    </div>
                    <div className={css.item}>
                        <img className={css.bullet} src={hexagon} />
                        <span>Real estimate, no price ranges and no sales calls</span>
                    </div>
                    <div className={css.item}>
                        <img className={css.bullet} src={hexagon} />
                        <span>If you like your quote, we can match you with a vetted contractor in your area</span>
                    </div>
                    <NamedLink name="SignupPage" className={css.signupLink}>
                        <span className={css.signup}>
                            <FormattedMessage id="LandingPage.SectionHero.button" />
                        </span>
                        <img src={Arrow} />
                    </NamedLink>
                </div>

            </div>
        </div>
    );
};

export default SectionDifference;