import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';

import { H2, NamedLink } from '../../../../components';

import phone from './phone.webp';
import css from './SectionPhone.module.css';

const SectionPhone = props => {
    return (
        <div className={css.root}>
            <H2 className={css.heading}>
                <FormattedMessage
                    id="LandingPage.SectionPhone.title"
                />
            </H2>

            <div className={css.container}>
                <img className={css.phone} src={phone} />
                <div className={css.info}>
                    <div className={css.item}>
                        <p>
                            <FormattedMessage id="LandingPage.SectionPhone.subtitle1" />
                        </p>
                        <FormattedMessage id="LandingPage.SectionPhone.info1" />
                    </div>
                    <div className={css.item}>
                        <p>
                            <FormattedMessage id="LandingPage.SectionPhone.subtitle2" />
                        </p>
                        <FormattedMessage id="LandingPage.SectionPhone.info2" />
                    </div>
                    <div className={css.item}>
                        <p>
                            <FormattedMessage id="LandingPage.SectionPhone.subtitle3" />
                        </p>
                        <FormattedMessage id="LandingPage.SectionPhone.info3" />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SectionPhone;