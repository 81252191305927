import React from 'react';
import { bool, object } from 'prop-types';
import { propTypes } from '../../util/types';

import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
// import SectionFooter from '../PageBuilder/SectionBuilder/SectionFooter';
import FooterContainer from '../FooterContainer/FooterContainer';

import SectionHero from './sections/SectionHero/SectionHero';
import SectionHow from './sections/SectionHow/SectionHow';
import SectionImage from './sections/SectionImage/SectionImage';
import SectionDifference from './sections/SectionDifference/SectionDifference';
import SectionWhoUses from './sections/SectionWhoUses/SectionWhoUses';
import SectionPhone from './sections/SectionPhone/SectionPhone';
import SectionContact from './sections/SectionContact/SectionContact';
import css from './LandingPage.module.css';
import SectionReviews from './sections/SectionReviews/SectionReviews';

const LandingPage = props => {
  return (
    <Page>
      <LayoutSingleColumn
        topbar={<TopbarContainer rootClassName={css.topbarRoot} />}
        footer={<FooterContainer />}
        className={css.root}
      >
        <SectionHero />
        <SectionHow />
        <SectionDifference />
        <SectionWhoUses />
        <SectionPhone />
        <SectionContact />
        <SectionReviews />
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPage.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
// const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
