import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';

import { H2 } from '../../../../components';

import HexagonBackground from './images/BackgroundHexagon.png';

import StarsImage from './images/stars.svg';
import css from './SectionReviews.module.css';

const SectionReviews = props => {
    return (
        <div className={css.root}>
            <img className={css.image} src={HexagonBackground} />
            <H2 className={css.heading}>
                <FormattedMessage
                    id="LandingPage.SectionReviews.title"
                />
            </H2>

            <div className={css.semiCircle} />

            <img src={StarsImage} className={css.stars} />

            <div className={css.reviewsFirstLineContainer}>

                <div className={css.reviewOuterContainer}>
                    <div className={css.reviewInnerContainer}>
                        <p className={css.reviewDetails}>
                            "Incredibly responsive, accurate and crushed the job."
                        </p>
                        <p className={css.reviewTitle}>- Steve D</p>
                    </div>
                </div>


                <div className={css.reviewOuterContainer}>
                    <div className={css.reviewInnerContainer}>
                        <p className={css.reviewDetails}>
                            "Using Tackl is easy and stress free!"
                        </p>
                        <p className={css.reviewTitle}>- Dan G</p>
                    </div>
                </div>

                <div className={css.reviewOuterContainer}>
                    <div className={css.reviewInnerContainer}>
                        <p className={css.reviewDetails}>
                            "Their exceptional service truly deserves an A++++ rating."
                        </p>
                        <p className={css.reviewTitle}>- Duke C</p>
                    </div>
                </div>



            </div>


            <div className={css.reviewsSecondLineContainer}>

                <div className={css.reviewOuterContainer}>
                    <div className={css.reviewInnerContainer}>
                        <p className={css.reviewDetails}>
                            "Using Tackl is easy and stress free!"
                        </p>
                        <p className={css.reviewTitle}>- Kelly H</p>
                    </div>
                </div>

                <div className={css.reviewOuterContainer}>
                    <div className={css.reviewInnerContainer}>
                        <p className={css.reviewDetails}>
                            "The Tackl team did a phenomenal job. It was easy and straightforward."
                        </p>
                        <p className={css.reviewTitle}>- Brian V</p>
                    </div>
                </div>

            </div>


        </div>
    );
};

export default SectionReviews;