import React from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';
import HeroImage from './img/HeroImage.png';
import Arrow from './img/triangle-24.png';

import css from './SectionHero.module.css';

const SectionHero = props => {
  return (
    <div className={css.root}>
      <div className={css.semiCircle} />
      <img src={HeroImage} className={css.heroImage} />
      <div className={css.container}>

        <H2 className={css.heading}>
          <FormattedMessage
            id="LandingPage.SectionHero.title"
            values={{ lineBreak: <br /> }}
          />
          <p className={css.subtitle}>
            <FormattedMessage
              id="LandingPage.SectionHero.info"
              values={{ lineBreak: <br /> }}
            />
          </p>
        </H2>
        <div className={css.content}>
          <p>
            <FormattedMessage
              id="LandingPage.SectionHero.content"
              values={{ lineBreak: <br /> }}
            />
          </p>
          <NamedLink name="SignupPage" className={css.signupLink}>
            <span className={css.signup}>
              <FormattedMessage id="LandingPage.SectionHero.button" />
            </span>
            <img src={Arrow} />
          </NamedLink>

        </div>
      </div>
    </div >
  );
};

export default SectionHero;
